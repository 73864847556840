/***
 *
 *   Chat
 *   Homepage for Chats settings
 *
 **********/

import React, { Fragment } from 'react';
import { Card, Form, Message, Button, useNavigate } from 'components/lib';

export function Chat(props) {
  const navigate = useNavigate();
  function onPressBot() {
    navigate('/chat/bots');
  }
  function onPressQuestion() {
    navigate('/chat/questions');
  }
  function onPressTrait() {
    navigate('/chat/traits');
  }

  function onPressContent() {
    navigate('/chat/content');
  }
  return (
    <Fragment>
      <Message
        title='Chat Settings'
        type='info'
        text='Customise chat bots setttings.'
      />

      <Card className='flex flex-row flex-wrap'>
        <Button
          text='Bots Settings'
          color='blue'
          className='mx-4'
          iconButton
          small
          action={onPressBot}
        />

        <Button
          text='Questions Settings'
          color='blue'
          className='mx-4'
          iconButton
          small
          action={onPressQuestion}
        />

        <Button
          text='Bots Traits Settings'
          color='blue'
          className='mx-4'
          iconButton
          small
          action={onPressTrait}
        />

        <Button
          text='Content Settings'
          color='blue'
          className='mx-4'
          iconButton
          small
          action={onPressContent}
        />
      </Card>
    </Fragment>
  );
}
