/***
 *
 *   SIGN IN
 *   Sign in
 *
 **********/

import { useContext, useEffect, useState } from 'react';
import {
  Animate,
  AuthContext,
  Form,
  Card,
  Row,
  useAPI,
  Loader,
  useNavigate,
} from 'components/lib';

export function AddBot(props) {
  // context
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  //Fetch
  const traits = useAPI('/api/traits');

  if (traits.loading) return <Loader />;

  return (
    <Animate type='pop'>
      <Row title='Add a Doc Bot'>
        <Card>
          <Form
            inputs={{
              name: {
                label: 'Name',
                type: 'text',
                required: true,
              },
              personality: {
                label: 'Personality',
                type: 'textarea',
                required: true,
              },
              treatmentApproach: {
                label: 'Treatment Approach',
                type: 'textarea',
                required: true,
              },
              basePrompt: {
                label: 'Base Prompt',
                type: 'textarea',
                required: true,
              },
              image: {
                label: 'Bot Avatar',
                type: 'file',
                required: false,
                max: 1,
              },
              traits: {
                label: 'Trait',
                type: 'checkboxTrait',
                required: true,
                min: 1,
                errorMessage: 'Please select at least one trait',
                options: traits.data,
                default: [],
              },
              temperature: {
                label: 'Temperature',
                type: 'number',
                min: 0,
                max: 1,
                required: true,
              },
            }}
            method='POST'
            buttonText='Add bot'
            url={`${context.remote_server}/api/bot`}
            callback={() => navigate(-1)}
          />
        </Card>
      </Row>
    </Animate>
  );
}
