import {
  AuthContext,
  Button,
  Card,
  Form,
  Message,
  Table,
  ViewContext,
  useAPI,
  useNavigate,
} from 'components/lib';
import { useContext } from 'react';

export function ContentSettings() {
  const navigate = useNavigate();
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const textContent = useAPI('/api/textContent');

  function deleteQuestion(data, callback) {
    viewContext.modal.show(
      {
        title: 'Delete Question',
        form: {},
        buttonText: 'Delete',
        text: `Are you sure you want to delete Question?`,
        url: `${authContext.remote_server}/api/question/${data.id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        viewContext.notification.show(`Question was deleted`, 'success', true);
        callback();
      }
    );
  }

  return (
    <Card loading={false}>
      <Card className='flex flex-row'>
        <Button
          text='Back'
          color='blue'
          className='mr-auto'
          small
          action={() => navigate(-1)}
        />
      </Card>

      <Card loading={textContent.loading}>
        <Form
          inputs={{
            consentText: {
              label: 'Consent Text',
              type: 'textarea',
              required: true,
              value: textContent.data?.consentText || '',
            },
          }}
          method='POST'
          buttonText='Save'
          url={`/api/textContent`}
        />
      </Card>
    </Card>
  );
}
