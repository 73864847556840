import axios from 'axios';
import {
  AuthContext,
  Button,
  Card,
  ViewContext,
  useNavigate,
} from 'components/lib';
import { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export function QuestionSettings() {
  const navigate = useNavigate();
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const [questions, setQuestions] = useState(null);

  const onDragEnd = ({ destination, source }) => {
    console.log(source);
    if (!destination) return;
    const copyQuestions = Array.from(questions);
    const [reorderedItem] = copyQuestions.splice(source.index, 1);
    copyQuestions.splice(destination.index, 0, reorderedItem);

    reorderQuestions(
      copyQuestions.map((question, index) => {
        question.index = index;
        return question;
      })
    );
    setQuestions(
      copyQuestions.map((question, index) => {
        question.index = index;
        return question;
      })
    );
  };

  async function reorderQuestions(updatedQuestions) {
    try {
      const res = await axios.put('/api/question/reorder', {
        newOrder: updatedQuestions,
      });
      viewContext.notification.show('Questions reordered', 'success', true);
    } catch (error) {
      viewContext.notification.show(
        'Error reordering questions',
        'error',
        true
      );
    }
  }

  function onAddTrait() {
    navigate('/chat/questions/add');
  }

  function deleteQuestion(data, callback) {
    viewContext.modal.show(
      {
        title: 'Delete Question',
        form: {},
        buttonText: 'Delete',
        text: `Are you sure you want to delete Question?`,
        url: `${authContext.remote_server}/api/question/${data.id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        viewContext.notification.show(`Question was deleted`, 'success', true);
        setQuestions(questions.filter(question => question.id !== data.id));
      }
    );
  }

  useEffect(() => {
    async function fetchQuestion() {
      try {
        const res = await axios.get('/api/questions');
        setQuestions(res.data.data);
      } catch (error) {}
    }
    fetchQuestion();
  }, []);

  return (
    <Card loading={false}>
      <Card className='flex flex-row'>
        <Button
          text='Back'
          color='blue'
          className='mr-auto'
          small
          action={() => navigate(-1)}
        />
        <Button
          text='Add New Question'
          color='blue'
          className='ml-auto'
          small
          action={onAddTrait}
        />
      </Card>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className='flex justify-center items-center'>
          <div className='grid w-full'>
            <Droppable droppableId='questionsList'>
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className='p-5 pt-7rounded-lg min-h-[200px]'
                >
                  {questions?.map((question, index) => (
                    <Draggable
                      key={question._id}
                      draggableId={question._id}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className='rounded-lg p-2 bg-gray-100 mb-2 flex justify-between items-center'
                        >
                          <span>{question.question}</span>

                          {/* Replace with your Button component */}
                          <Button
                            icon='trash'
                            action={() => deleteQuestion(question)}
                            className='inline-block whitespace-nowrap bg-transparent'
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
    </Card>
  );
}
