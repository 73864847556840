/***
 *
 *   SIGN IN
 *   Sign in
 *
 **********/

import { useContext } from 'react';
import {
  Animate,
  AuthContext,
  Form,
  Card,
  Row,
  useNavigate,
} from 'components/lib';

export function AddQuestion(props) {
  // context

  const navigate = useNavigate();
  const context = useContext(AuthContext);

  function onQuestionAddedSucessfully() {
    navigate(-1);
  }

  return (
    <Animate type='pop'>
      <Row title='Add a new Question'>
        <Card center>
          <Form
            inputs={{
              question: {
                label: 'question',
                type: 'text',
                required: true,
              },
            }}
            method='POST'
            buttonText='Add Question'
            url={`${context.remote_server}/api/question`}
            callback={onQuestionAddedSucessfully}
          />
        </Card>
      </Row>
    </Animate>
  );
}
