import axios from 'axios';
import {
  AuthContext,
  Button,
  Card,
  Message,
  Table,
  useAPI,
  useNavigate,
} from 'components/lib';
import { useContext, useEffect, useState } from 'react';

export function BotSettings() {
  const [bots, setBots] = useState(null);
  const [loading, setLoading] = useState(false);
  const context = useContext(AuthContext);

  const navigate = useNavigate();

  function onAddBot() {
    navigate('/chat/bots/add');
  }

  async function getBots() {
    try {
      setLoading(true);
      let res = await axios.get(`${context.remote_server}/api/bots`);
      setBots(res.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function deleteBot(bot) {
    try {
      setLoading(true);
      let res = await axios.delete(
        `${context.remote_server}/api/bot/${bot.id}`
      );
      getBots();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getBots();
  }, []);

  function editBot(bot) {
    navigate(`/chat/bots/${bot.id}`);
  }

  return (
    <Card loading={false}>
      <Card className='flex flex-row'>
        <Button
          text='Back'
          color='blue'
          className='mr-auto'
          small
          action={() => navigate(-1)}
        />
        <Button
          text='Add New Bot'
          color='blue'
          className='ml-auto'
          small
          action={onAddBot}
        />
      </Card>

      <Card>
        <Table
          search
          show={['name', 'personality', 'disabled']}
          data={bots}
          loading={loading}
          actions={{
            edit: editBot,
            delete: deleteBot,
          }}
        />
      </Card>
    </Card>
  );
}
