/***
 *
 *   SIGN IN
 *   Sign in
 *
 **********/

import { useContext, useEffect, useState } from 'react';
import {
  Animate,
  AuthContext,
  Form,
  Card,
  Row,
  useAPI,
  Loader,
  useNavigate,
  Button,
} from 'components/lib';
import { useParams } from 'react-router-dom';

export function EditBot(props) {
  const { id } = useParams();

  // context
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const bot = useAPI(`${context.remote_server}/api/bot/${id}`);

  //Fetch
  const traits = useAPI('/api/traits');

  return (
    <Animate type='pop'>
      <Card className='flex flex-row'>
        <Button
          text='Back'
          color='blue'
          className='mr-auto'
          small
          action={() => navigate(-1)}
        />
      </Card>
      <Row title='Edit Bot'>
        <Card loading={bot.loading || traits.loading}>
          {bot?.data && (
            <Form
              inputs={{
                name: {
                  label: 'Name',
                  type: 'text',
                  required: true,
                  value: bot.data.name,
                },
                personality: {
                  label: 'Personality',
                  type: 'textarea',
                  required: true,
                  value: bot.data.personality,
                },
                treatmentApproach: {
                  label: 'Treatment Approach',
                  type: 'textarea',
                  required: true,
                  value: bot.data.treatmentApproach,
                },
                basePrompt: {
                  label: 'Base Prompt',
                  type: 'textarea',
                  required: true,
                  value: bot.data.basePrompt,
                },
                image: {
                  label: 'Bot Avatar',
                  type: 'file',
                  required: false,
                  max: 1,
                },
                traits: {
                  label: 'Trait',
                  type: 'checkboxTrait',
                  required: true,
                  min: 1,
                  errorMessage: 'Please select at least one trait',
                  options: traits.data,
                  default: bot.data.traits,
                },
                temperature: {
                  label: 'Temperature',
                  type: 'number',
                  value: bot.data.temperature || 0,
                  min: 0,
                  max: 1,
                  required: true,
                },
              }}
              method='PATCH'
              buttonText='Save'
              url={`${context.remote_server}/api/bot/${id}`}
              callback={() => navigate(-1)}
            />
          )}
        </Card>
      </Row>
    </Animate>
  );
}
