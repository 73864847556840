/***
 *
 *   SIGN IN
 *   Sign in
 *
 **********/

import { useContext, useEffect, useState } from 'react';
import {
  Animate,
  AuthContext,
  Form,
  Card,
  Row,
  useNavigate,
  useAPI,
} from 'components/lib';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export function UpdateTrait(props) {
  // context
  const { id } = useParams();
  
  console.log("the id is",id);
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  function onTraitAddedSucessfully() {
    navigate(-1);
  }
  const trait = useAPI(`${context.remote_server}/api/traits/${id}`);
  console.log("the triat is",trait?.data?.name);

// useEffect(async()=>{
//   try {
//     // console.log("tje id is",id);
   
//     const res = await axios.post(`${context.remote_server}/api/traits/single`, {
//       id:id
//     });
//     console.log("the response is",res?.data?.data);
//     if(res?.data?.data)
//     setTrait(res?.data?.data)
//     // if(res)
//     // {
//     //   window.location.reload();
//     // }

//   } catch (error) {
//   } finally {

//   }
// },[])
// useEffect(()=>{
// console.log("the trait is",trait?.name);
// },[trait])
  return (
    <Animate type='pop'>
      <Row title='Update bot trait '>
        <Card center>
        {trait?.data && (
          <Form
            inputs={{
              name: {
                label: 'name',
                type: 'text',
                required: true,
                value:trait?.data?.name
                
              },
              description: {
                label: 'description',
                type: 'text',
                required: true,
                value:trait?.data?.description

              },
            }}
            method='PATCH'
            buttonText='Update Trait'
            url={`${context.remote_server}/api/trait/${id}`}
            callback={onTraitAddedSucessfully}
          />)}
        
        </Card>
        
      </Row>
    </Animate>
  );
}
