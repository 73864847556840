import { Signin } from 'views/signin';
import { Dashboard } from 'views/dashboard';
import { Accounts } from 'views/accounts';
import { Users } from 'views/users';
import { Feedback } from 'views/feedback';
import { Logs } from 'views/log/list';
import { LogDetail } from 'views/log/detail';
import { EventGroups } from 'views/event/groups';
import { Events } from 'views/event/list';
import { EventDetail } from 'views/event/detail';
import { Chat } from 'views/chat/chat';
import { QuestionSettings } from 'views/chat/questionSettings';
import { BotSettings } from 'views/chat/botSettings';
import { TraitSettings } from 'views/chat/traitSettings';
import { AddBot } from 'views/chat/addBot';
import { AddTrait } from 'views/chat/addTrait';
import { UpdateTrait } from 'views/chat/updateTrait';
import { AddQuestion } from 'views/chat/addQuestion';
import { EditBot } from 'views/chat/editBot';
import { ContentSettings } from 'views/chat/contentSettings';

const Routes = [
  {
    path: '/',
    view: Signin,
    layout: 'auth',
    title: 'Mission Control',
  },
  {
    path: '/signin',
    view: Signin,
    layout: 'auth',
    title: 'Mission Control',
  },
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'master',
    title: 'Mission Control',
  },
  {
    path: '/accounts',
    view: Accounts,
    layout: 'app',
    permission: 'master',
    title: 'Accounts',
  },
  {
    path: '/users',
    view: Users,
    layout: 'app',
    permission: 'master',
    title: 'Users',
  },
  {
    path: '/feedback',
    view: Feedback,
    layout: 'app',
    permission: 'master',
    title: 'User Feedback',
  },
  {
    path: '/logs',
    view: Logs,
    layout: 'app',
    permission: 'master',
    title: 'App Logs',
  },
  {
    path: '/logs/:id',
    view: LogDetail,
    layout: 'app',
    permission: 'master',
    title: 'Log Detail',
  },
  {
    path: '/events',
    view: EventGroups,
    layout: 'app',
    permission: 'master',
    title: 'Events',
  },
  {
    path: '/events/:group',
    view: Events,
    layout: 'app',
    permission: 'master',
    title: 'Event',
  },
  {
    path: '/events/:group/:id',
    view: EventDetail,
    layout: 'app',
    permission: 'master',
    title: 'Event Detail',
  },
  {
    path: '/chat',
    view: Chat,
    layout: 'app',
    permission: 'master',
    title: 'Chat',
  },
  {
    path: '/chat/questions',
    view: QuestionSettings,
    layout: 'app',
    permission: 'master',
    title: 'Questions',
  },
  {
    path: '/chat/questions/add',
    view: AddQuestion,
    layout: 'app',
    permission: 'master',
    title: 'Questions',
  },
  {
    path: '/chat/content',
    view: ContentSettings,
    layout: 'app',
    permission: 'master',
    title: 'Questions',
  },
  {
    path: '/chat/bots',
    view: BotSettings,
    layout: 'app',
    permission: 'master',
    title: 'Bot Settings',
  },
  {
    path: '/chat/bots/:id',
    view: EditBot,
    layout: 'app',
    permission: 'master',
    title: 'New Bot',
  },
  {
    path: '/chat/bots/add',
    view: AddBot,
    layout: 'app',
    permission: 'master',
    title: 'New Bot',
  },
  {
    path: '/chat/traits',
    view: TraitSettings,
    layout: 'app',
    permission: 'master',
    title: 'Bot Traits',
  },
  {
    path: '/chat/traits/add',
    view: AddTrait,
    layout: 'app',
    permission: 'master',
    title: 'Bot Traits',
  },
  {
    path: '/chat/traits/:id',
    view: UpdateTrait,
    layout: 'app',
    permission: 'master',
    title: 'Update Bot Traits',
  },
];

export default Routes;
