import axios from 'axios';
import {
  AuthContext,
  Button,
  Card,
  Message,
  Table,
  View,
  useAPI,
  useNavigate,
} from 'components/lib';
import { useContext } from 'react';

export function TraitSettings() {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const traits = useAPI('/api/traits');

  function onAddTrait() {
    navigate('/chat/traits/add');
  }
function editTrait(row){
 
    navigate(`/chat/traits/${row.id}`);
  
}
async function DeleteTrait(row){
  try {
    // console.log("tje id is",id);
   
    const res = await axios.post(`${context.remote_server}/api/trait/delete`, {
      id:row?.id
    });
    if(res)
    {
      window.location.reload();
    }

  } catch (error) {
  } finally {

  }
  // const deleteTrait = useAPI('/api/traits/delete','post',{id:"30775b66-710a-4081-83de-8a01959e48ad"});
}
  return (
    <>
      <Card loading={false}>
        <Card className='flex flex-row'>
          <Button
            text='Back'
            color='blue'
            className='mr-auto'
            small
            action={() => navigate(-1)}
          />
          <Button
            text='Add New Trait'
            color='blue'
            className='ml-auto'
            small
            action={onAddTrait}
          />
        </Card>

        <Card>
          <Table
            search
            show={['name', 'description']}
            data={traits?.data}
            loading={traits?.loading}
            actions={{ edit: editTrait, delete: DeleteTrait  }}
          />
        </Card>
      </Card>
    </>
  );
}
